// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_sM d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_sN d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_sP d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_sQ d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_sR d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_sS d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_sT d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_rP q_rP";
export var heroExceptionNormal = "s_rQ q_rQ";
export var heroExceptionLarge = "s_rR q_rR";
export var Title1Small = "s_rq q_rq q_qW q_qX";
export var Title1Normal = "s_rr q_rr q_qW q_qY";
export var Title1Large = "s_rs q_rs q_qW q_qZ";
export var BodySmall = "s_rF q_rF q_qW q_rd";
export var BodyNormal = "s_rG q_rG q_qW q_rf";
export var BodyLarge = "s_rH q_rH q_qW q_rg";